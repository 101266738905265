
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { useModals } from '@/compositions/modals'
import TicketsSettingsInboxesTableActions from '@/components/pages/tickets/settings/inboxes/TicketsSettingsInboxesTableActions.vue'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
    TmTable,
    Highlight,
    TicketsSettingsInboxesTableActions,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
