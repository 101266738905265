
import { defineComponent } from 'vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    EmptyState,
    TmWatchTutorial,
    TmButton,
  },
})
