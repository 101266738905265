
import { defineComponent, ref, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TicketsSettingsInboxesFilter from '@/components/pages/tickets/settings/inboxes/TicketsSettingsInboxesFilter.vue'
import TicketsSettingsInboxesTable from '@/components/pages/tickets/settings/inboxes/TicketsSettingsInboxesTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import TicketsSettingsInboxesEmpty from '@/components/pages/tickets/settings/inboxes/TicketsSettingsInboxesEmpty.vue'

export default defineComponent({
  components: {
    TicketsSettingsInboxesEmpty,
    PageContent,
    DetailsHero,
    TicketsSettingsInboxesFilter,
    TicketsSettingsInboxesTable,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Connected inboxes', url: '' },
    ]

    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Email', value: 'email-slot' },
      { text: 'Type', value: 'type-slot' },
      { text: 'Inbound', value: 'inbound-slot' },
      { text: 'Outbound', value: 'outbound-slot' },
    ])
    const tableItems = ref([
      {
        email: 'support@company.textmagic.com',
        isSystem: true,
        type: {
          logo: require('@/assets/images/logo-small.svg'),
          text: 'Textmagic',
        },
        inbound: true,
        outbound: {
          status: true,
        },
      },
      {
        email: 'ausupport@company.textmagic.com',
        isSystem: false,
        type: {
          logo: require('@/assets/images/logo-small.svg'),
          text: 'Textmagic',
        },
        inbound: true,
        outbound: {
          status: true,
        },
      },
      {
        email: 'support.company@gmail.com',
        isSystem: false,
        type: {
          logo: require('@/assets/images/gmail-logo.svg'),
          text: 'Gmail',
        },
        inbound: false,
        outbound: {
          status: false,
          issues: 1,
        },
      },
      {
        email: 'support.company@gmail.com',
        isSystem: false,
        type: {
          logo: require('@/assets/images/gmail-logo.svg'),
          text: 'Gmail',
        },
        inbound: true,
        outbound: {
          status: false,
          issues: 1,
        },
      },
      {
        email: 'support@company.com',
        isSystem: false,
        type: {
          icon: 'tmi-email-send-direct',
          text: 'Forwarding',
        },
        inbound: true,
        outbound: {
          status: false,
          issues: 2,
        },
      },
    ])
    const filteredTableItems = computed(() => {
      if (!search.value) return tableItems.value
      return tableItems.value.filter((item) => item.email.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      breadcrumbs,
      tableHeaders,
      filteredTableItems,
      search,
    }
  },
})
