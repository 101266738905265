import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_tickets_settings_inboxes_empty = _resolveComponent("tickets-settings-inboxes-empty")!
  const _component_tickets_settings_inboxes_filter = _resolveComponent("tickets-settings-inboxes-filter")!
  const _component_tickets_settings_inboxes_table = _resolveComponent("tickets-settings-inboxes-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-white": _withCtx(() => [
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_tickets_settings_inboxes_empty, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_tickets_settings_inboxes_filter, {
              search: _ctx.search,
              "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              "search-placeholder": "Search inboxes"
            }, null, 8, ["search"]),
            _createVNode(_component_tickets_settings_inboxes_table, {
              headers: _ctx.tableHeaders,
              items: _ctx.filteredTableItems,
              "item-key": "id",
              "items-name": "inboxes",
              search: _ctx.search
            }, null, 8, ["headers", "items", "search"])
          ], 64))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Connected inboxes",
        icon: "tmi-inbox-open",
        class: "mb-4"
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}